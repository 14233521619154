import React, { useEffect, useContext, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';
import he from 'he';
import PropTypes from 'prop-types';
import Layout from '../layouts';
import FlexibleContent from '../components/blog/FlexibleContent';
import SocialShare from '../components/blog/SocialShare';
import { HeaderContext } from '../context/HeaderContext';
import { useSiteMetadata } from '../hooks/use-site-metadata'
import SEO from '../components/seo/SEO';
import Disqus from 'gatsby-plugin-disqus';

function PostTemplate(props) {
  const headerContext = useContext(HeaderContext);

  const ctxRef = useRef(headerContext);

  useEffect(() => {
    var ctx = ctxRef;

    ctx.current.showBackToBlog();
    ctx.current.setPath(props.location.pathname);
    ctx.current.setCategory(props.data.wordpressPost.categories[0].slug)
    //this.getBlog( true, "");

    return () => {
      ctx.current.hideBackToBlog();
      ctx.current.setPath('');
      ctx.current.setCategory(null);
    }
  }, [props]);
 
  const post = props.data.wordpressPost;
  const site = useSiteMetadata();

  const { siteUrl, twitterHandle } = site;
  const { id, title, date, author, featured_media, content  } = post;
  const { next, prev, tags } = props.pageContext;
  const authorImg = (author.simple_local_avatar) ? author.simple_local_avatar.full.localFile.childCloudinaryAsset.fluid : null;
  const fullPath = props.location.pathname;

  const postTitle = post.yoast_meta.yoast_wpseo_title
    ? post.yoast_meta.yoast_wpseo_title
    : `${title}`;

  const nextPrevClass =
    headerContext && !headerContext.state.visibleUI ? 'hidden' : '';


  return (
    <Layout>
      <Helmet>
        <title>{postTitle} | Firewood</title>
      </Helmet>
      <SEO node={post} site={site} isBlogPost />
      <div className={`single-next-prev ${nextPrevClass}`}>
        {prev && (
          <Link
            to={`/news/${prev.categories[0].slug}/${prev.slug}`}
            className='previous'
          >
            <i className='fas fa-arrow-left' />
            <div className='content'>
              <div className='content-inner'>
                <div className='category'>
                  {he.decode(prev.categories[0].name)}
                </div>
                <div className='title'>{he.decode(prev.title)}</div>
                <div className='date'>{prev.date}</div>
              </div>
            </div>
          </Link>
        )}
        {next && (
          <Link
            to={`/news/${next.categories[0].slug}/${next.slug}`}
            className='next'
          >
            <i className='fas fa-arrow-right' />
            <div className='content'>
              <div className='content-inner'>
                <div className='category'>
                  {he.decode(next.categories[0].name)}
                </div>
                <div className='title'>{he.decode(next.title)}</div>
                <div className='date'>{next.date}</div>
              </div>
            </div>
          </Link>
        )}
      </div>
      <section className='section-single-post hero'>
        <div className='content'>
          <div className='hentry'>
            <h1
              className='entry-title'
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className='post-info'>
              <div className='info author-date'>
                by <a href={`/news/author/${author.slug}`}>{author.name}</a> &nbsp;|&nbsp; {date}
              </div>
              {tags && (
                <div className='info tags'>
                  {tags.map((tag, i) => {
                    return (
                      <Link key={i} to={`/news/topics/${tag.slug}`}>
                        <span>#</span> {he.decode(tag.name)}
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        {featured_media && (
          <div className='post-thumbnail'>
            <Img
              fluid={featured_media.localFile.childCloudinaryAsset.fluid}
              className='gatsby-image-relative'
            />
            <div
              className='eyebrow'
              style={{ textAlign: 'center', marginBottom: '25px' }}
            >
              Share
            </div>
            <SocialShare
              socialConfig={{
                twitterHandle,
                config: {
                  url: `${siteUrl}${fullPath}`,
                  title
                }
              }}
              // tags={tags}
            />
          </div>
        )}
        {content && (
          <div
            className='hentry'
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </section>
      <FlexibleContent currentPost={post} />
      
      {author.description && (
      <section className='section-single-post about-author'>
        <div className='content'>
          <div className='content--main copy'>
            <hr/>
            <div className="row">
              {authorImg && (
              <div className="column author-img">
                <Img fluid={authorImg} />
              </div>
              )}
              <div className="column author-bio">
                <p><b>About {author.name}</b></p>
                <span dangerouslySetInnerHTML={{ __html: author.description}} />
              </div>
            </div>
          </div>
        </div>
      </section>
      )}

      <section className='section-single-post'>
        <div className='content'>
          <div className='content--main'>
          <Disqus 
            identifier={id}
            title={postTitle}
          />
          </div>
        </div>
      </section>
    </Layout>
  );
  
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
};

export default PostTemplate;

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMM Do, YYYY")
      author {
        name
        slug
        description
        simple_local_avatar {
          full {
            localFile{
              childCloudinaryAsset{
                fluid(maxWidth: 200){
                  ...CloudinaryAssetFluid
                }
              }
            }
          }
        }
      }
      categories {
        name
        slug
      }
      featured_media {
        localFile {
          childCloudinaryAsset {
            fluid(maxWidth: 2500, transformations: ["ar_16:9","c_fill","dpr_auto"]) {
              ...CloudinaryAssetFluid
            }
          }
        }
      }
      ...Content
      ...Image
      ...ImageGallery
      ...VideoIframe
      # ...PostInterstitial
      ...SEO
    }
  }
`;
