import React from 'react';
import { graphql } from 'gatsby';

export default ({ node }) => {
  return (
    <section className={`section-single-post section-video`}
    ref={(el) => {
      if (el){
        if (node.custom_bottom_padding.yes && node.custom_bottom_padding.bottom_padding !== '5') {
        el.style.setProperty('padding-bottom', `${node.custom_bottom_padding}em`, 'important');
        }
      }
    }}>
      <div className='content'>
        <div className='content--main'>
          <div
            className='video-wrapper'
            dangerouslySetInnerHTML={{ __html: node.video }}
          />
        </div>
      </div>
    </section>
  );
};

export const contentQuery = graphql`
  fragment VideoIframe on wordpress__POST {
    acf {
      flexible_content_post {
        __typename
        ... on WordPressAcf_video {
          video
          custom_bottom_padding {
            bottom_padding
            yes
          }
        }
      }
    }
  }
`;
