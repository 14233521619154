import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

export default ({ node, index }) => {
  const { image, custom_bottom_padding, caption, link } = node;
  const isGatsbyImage =
    image && image.localFile.childCloudinaryAsset === null ? false : true;
  return (
    <section className="section-single-post image"
    ref={(el) => {
      if (el){
        if (custom_bottom_padding.yes && custom_bottom_padding.bottom_padding !== '5') {
        el.style.setProperty('padding-bottom', `${custom_bottom_padding}em`, 'important');
        }
      }
    }}>
      <div className="content">
        <div className="content--main">
          {image && isGatsbyImage && !link && (
            <Img
              fluid={image.localFile.childCloudinaryAsset.fluid}
              className="gatsby-image-relative"
              imgStyle={{
                objectFit: "initial",
              }}
            />
          )}
          {image && isGatsbyImage && link && (
            <a href={link} target="_blank" rel="noopener noreferrer">
              <Img
                fluid={image.localFile.childCloudinaryAsset.fluid}
                className="gatsby-image-relative"
              />
            </a>
          )}
          {image && !isGatsbyImage && (
            <img
              src={image.localFile.publicURL}
              className="image-responsive"
              alt=""
            />
          )}
          {image && caption && <p>{caption}</p>}
        </div>
      </div>
    </section>
  );
};

export const imageQuery = graphql`
  fragment Image on wordpress__POST {
    acf {
      flexible_content_post {
        __typename
        ... on WordPressAcf_image_single {
          image {
            localFile {
              publicURL
              childCloudinaryAsset {
                fluid(maxWidth: 2500, chained: ["w_auto,c_scale","c_limit"]) {
                  ...CloudinaryAssetFluid
                }
              }
            }
          }
          custom_bottom_padding {
            bottom_padding
            yes
          }
          caption
          link
        }
      }
    }
  }
`;
