import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

export default ({ node }) => {
  const {
    copy,
    custom_bottom_padding,
    add_image,
    add_highlighted_copy,
    highlighted_copy,
    highlight_alignment,
    image,
    image_alignment
  } = node;

  const isGatsbyImage =
    image && image.localFile.childCloudinaryAsset === null ? false : true;

  let sectionClass = '';
  if (add_image && image) sectionClass = ` more more-${image_alignment}`;
  if (add_highlighted_copy && highlighted_copy)
    sectionClass = ` more more-${highlight_alignment}`;
  if (custom_bottom_padding.yes && custom_bottom_padding.bottom_padding !== '5')
    sectionClass += ` custom-padding-${custom_bottom_padding.bottom_padding}`;

  return (
    <section className={`section-single-post${sectionClass}`}>
      <div className='content'>
        {copy && (
          <div
            className='content--main copy'
            dangerouslySetInnerHTML={{ __html: copy }}
          />
        )}
        {add_highlighted_copy && highlighted_copy && (
          <div className='content--highlight'>
            <div className='outer'>
              <div
                className='inner'
                dangerouslySetInnerHTML={{ __html: highlighted_copy }}
              />
            </div>
          </div>
        )}
        {add_image && image && isGatsbyImage && (
          <div className='content--image'>
            <Img
              fluid={image.localFile.childCloudinaryAsset.fluid}
              className='gatsby-image-relative auto-height'
            />
          </div>
        )}
        {add_image && image && !isGatsbyImage && (
          <div className='content--image'>
            <img
              src={image.localFile.publicURL}
              className='image-responsive'
              alt=''
            />
          </div>
        )}
      </div>
    </section>
  );
};

export const contentQuery = graphql`
  fragment Content on wordpress__POST {
    acf {
      flexible_content_post {
        __typename
        ... on WordPressAcf_content {
          copy
          custom_bottom_padding {
            bottom_padding
            yes
          }
          add_image
          add_highlighted_copy
          highlighted_copy
          highlight_alignment
          image {
            localFile {
              publicURL
              childCloudinaryAsset {
                fluid(maxWidth: 1080) {
                  ...CloudinaryAssetFluid
                }
              }
            }
          }
          image_alignment
        }
      }
    }
  }
`;
