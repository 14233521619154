import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from 'react-share';
import PropTypes from 'prop-types';

const SocialShare = ({ socialConfig, tags }) => {
  return (
    <div className="post-social">
      <FacebookShareButton
        url={socialConfig.config.url}
        className="button is-outlined is-rounded facebook"
      >
        <span className="icon">
          <i className="fab fa-facebook-f" />
        </span>
        <span className="text">Facebook</span>
      </FacebookShareButton>
      <TwitterShareButton
        url={socialConfig.config.url}
        className="button is-outlined is-rounded twitter"
        title={socialConfig.config.title}
        via={socialConfig.twitterHandle.split('@').join('')}
        hashtags={tags}
      >
        <span className="icon">
          <i className="fab fa-twitter" />
        </span>
        <span className="text">Twitter</span>
      </TwitterShareButton>
      <LinkedinShareButton
        url={socialConfig.config.url}
        className="button is-outlined is-rounded linkedin"
        title={socialConfig.config.title}
      >
        <span className="icon">
          <i className="fab fa-linkedin-in" />
        </span>
        <span className="text">LinkedIn</span>
      </LinkedinShareButton>
    </div>
  );
};

SocialShare.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string)
};
SocialShare.defaultProps = {
  tags: []
};

export default SocialShare;
