import React from 'react';
import Content from './Content';
import Image from './Image';
import ImageGallery from './ImageGallery';
import VideoIframe from './VideoIframe';
// import PostInterstitial from './PostInterstitial';

const FlexibleContent = ({ currentPost, ...props }) => {
  return (
    currentPost.acf.flexible_content_post !== null &&
    currentPost.acf.flexible_content_post.map((layout, i) => {
      if (layout.__typename === `WordPressAcf_content`) {
        return <Content key={i} index={i} node={layout} />;
      }
      if (layout.__typename === `WordPressAcf_image_single`) {
        return <Image key={i} index={i} node={layout} />;
      }
      if (layout.__typename === `WordPressAcf_image_gallery`) {
        return <ImageGallery key={i} index={i} node={layout} />;
      }
      if (layout.__typename === `WordPressAcf_video`) {
        return <VideoIframe key={i} index={i} node={layout} />;
      }
      /* if (layout.__typename === `WordPressAcf_interstitial`) {
        return <PostInterstitial key={i} index={i} node={layout} />;
      } */
      return null;
    })
  );
};

export default FlexibleContent;
