import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

export default ({ node }) => {
  const { images, full_width, caption, custom_bottom_padding } = node;

  function renderImages() {
    return images.map((node, i) => {
      const image = node.image;
      const isGatsbyImage =
        image && image.localFile.childCloudinaryAsset === null ? false : true;
      return (
        <div key={i} className="gallery-item">
          {image && isGatsbyImage && (
            <Img fluid={image.localFile.childCloudinaryAsset.fluid} />
          )}
          {image && !isGatsbyImage && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                backgroundImage: `url(${image.localFile.publicURL})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            />
          )}
        </div>
      );
    });
  }

  return (
    <section className="section-single-post section-image-gallery" ref={(el) => {
      if (el){
        if (custom_bottom_padding.yes && custom_bottom_padding.bottom_padding !== '5') {
        el.style.setProperty('padding-bottom', `${custom_bottom_padding}em`, 'important');
        }
      }
    }}>
      {full_width && (
        <div className="full-width-gallery">
          <div className="blog-image-gallery">{renderImages()}</div>
          {caption && (
            <div className="content">
              <div className="content--main">
                <p>{caption}</p>
              </div>
            </div>
          )}
        </div>
      )}
      {!full_width && (
        <div className="content">
          <div className="content--main">
            <div className="blog-image-gallery">{renderImages()}</div>
            {caption && <p>{caption}</p>}
          </div>
        </div>
      )}
    </section>
  );
};

export const imageGalleryQuery = graphql`
  fragment ImageGallery on wordpress__POST {
    acf {
      flexible_content_post {
        __typename
        ... on WordPressAcf_image_gallery {
          full_width
          caption
          custom_bottom_padding {
            bottom_padding
            yes
          }
          images {
            image {
              localFile {
                publicURL
                childCloudinaryAsset {
                  fluid(maxWidth: 900, chained: ["w_auto,c_scale","c_limit"]) {
                    ...CloudinaryAssetFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
